import React from 'react';
import Partners from '../../components/sections/partners/Partners';

export default function PartnersView({
  data,
  ...rest
}) {
  return (
    <Partners data={data} />
  );
}
