import React, { useMemo, useState, useCallback } from 'react';
import classNames from 'classnames';
import PhoneInput from 'react-phone-input-2';
import fr from 'react-phone-input-2/lang/fr.json';
import 'react-phone-input-2/lib/bootstrap.css';
import { useDropzone } from 'react-dropzone';

export default function Input({
  children,
  type = 'text',
  name,
  label,
  error,
  locale,
  required,
  readOnly,
  preferredCountries = [
    'hk',
    'us',
    'fr',
    'gb',
    'at',
    'de',
    'nz',
  ],
  excludeCountries = [
    'af',
    'am',
    'az',
    'bt',
    'bd',
    'io',
    'bn',
    'kh',
    'ge',
    'gu',
    'in',
    'fj',
    'kz',
    'ki',
    'kg',
    'la',
    'my',
    'mv',
    'mh',
    'fm',
    'mn',
    'mm',
    'np',
    'nc',
    'kp',
    'pk',
    'pw',
    'pg',
    'ph',
    'ws',
    'sb',
    'lk',
    'tj',
    'tl',
    'to',
    'tm',
    'tv',
    'uz',
    'vu',
  ],
  regions = ['eu-union', 'north-america', 'oceania', 'asia'],
  dragAccept = 'Drop files',
  dragReject = 'Some files will be rejected',
  dragActive = 'Drag \'n\' drop some files here, or click to select files',
  thumbReplace,
  thumbComponent: ThumbComponent,
  ...rest
}) {
  const [files, setFiles] = useState([]);
  const finalOnDrop = useCallback((acceptedFiles) => {
    if (rest.onDrop) {
      rest.onDrop(acceptedFiles);
    }
    setFiles(acceptedFiles);
  }, [rest, setFiles]);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    ...rest,
    onDrop: finalOnDrop,
  });
  const input = useMemo(() => {
    if (['textarea', 'multiline'].includes(type)) {
      return (
        <textarea
          className={classNames({
            'invalid-value': error,
          })}
          name={name}
          required={required}
          readOnly={readOnly}
          {...rest}
        />
      );
    } else if (type === 'tel') {
      return (
        <PhoneInput
          inputProps={{
            name,
            required,
            readOnly,
          }}
          preferredCountries={preferredCountries}
          excludeCountries={excludeCountries}
          regions={regions}
          localization={locale === 'fr' ? fr : undefined}
          isValid={(inputNumber, country, countries) => countries.some((country) => {
            if (error) {
              return false;
            }
            return (inputNumber.startsWith(country.dialCode) || country.dialCode.startsWith(inputNumber)) && (inputNumber.length < 4 || inputNumber.length > 9);
          })}
          {...rest}
        />
      );
    } else if (type === 'dropzone') {
      return (
        <>
          <div
            {...getRootProps({
              className: classNames('dropzone', {
                hidden: !(!thumbReplace || (thumbReplace && ThumbComponent && !files.length)),
                active: !isDragActive,
                accept: isDragAccept,
                reject: isDragReject,
              })
            })}
          >
            <input
              readOnly={readOnly}
              name={name}
              {...getInputProps()}
            />
            <p>
              {isDragAccept && dragAccept}
              {isDragReject && dragReject.replace('{maxSize}', `${Math.round(rest.maxSize / 1024 / 1024).toString()}MB`)}
              {!isDragActive && dragActive}
            </p>
          </div>
          {ThumbComponent && <ThumbComponent files={files} setFiles={setFiles} />}
        </>
      );
    }
    return (
      <input
        readOnly={readOnly}
        className={classNames({ 'invalid-value': error })}
        type={type}
        name={name}
        required={required}
        {...rest}
      />
    );
  }, [
    type,
    error,
    name,
    required,
    readOnly,
    rest,
    ThumbComponent,
    setFiles,
    files,
    getRootProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    getInputProps,
    dragAccept,
    dragReject,
    dragActive,
    excludeCountries,
    locale,
    preferredCountries,
    regions,
    thumbReplace
  ]);
  return (
    <div className="form-control">
      <label htmlFor={name}>
        {label}
      </label>
      {input}
      <div
        className={classNames('invalid-message', 'text-xxs', {
          visible: error,
        })}
      >
        {error || '\xa0'}
      </div>
      {children}
    </div>
  );
}
