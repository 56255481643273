import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../../utils/SectionProps';
import PartnerList from './PartnerList';
import bannerImg from '../../../assets/images/partners/pen-4337521_1920-xl.jpg';
import bannerImgWebp from '../../../assets/images/partners/pen-4337521_1920-xl.jpg?format=webp';
import ImageSet from '../../elements/ImageSet';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Partners = ({
  data,
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'Partners section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'Partners-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div>
        <div className="banner">
          <h2 className="partners-title title mt-0 mb-0">
            Our partners
          </h2>
          <ImageSet
            alt="partners"
            webp={bannerImgWebp}
            {...bannerImg}
          />
        </div>
        <div className={innerClasses}>
          <div className="Partners-content container-xs">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Our <span className="text-color-primary">network of companies</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                We build and operationally develop high potential businesses across various verticals in a number of countries. Explore our portfolio below.
              </p>
            </div>
            <div className="container">
              <PartnerList partners={data.companies} />
              {/*<div className="reveal-from-bottom" data-reveal-delay="600">*/}
              {/*  <ButtonGroup>*/}
              {/*    <Button tag="a" color="primary" wideMobile href="https://cruip.com/">*/}
              {/*      Get started*/}
              {/*      </Button>*/}
              {/*    <Button tag="a" color="dark" wideMobile href="https://github.com/cruip/open-react-template/">*/}
              {/*      View on Github*/}
              {/*      </Button>*/}
              {/*  </ButtonGroup>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Partners.propTypes = propTypes;
Partners.defaultProps = defaultProps;

export default Partners;
