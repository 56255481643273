import cn from 'classnames';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Link,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

export default function Partner({
  partners,
}) {
  const { path } = useRouteMatch();
  const { partnerSlug } = useParams();
  const partner = partners.find((partner) => partner.slug === partnerSlug);
  const partnerIndex = partners.findIndex((partner) => partner.slug === partnerSlug);
  const subPath = path.replace('/:partnerSlug', '');
  const prevPath = useMemo(() => partnerIndex ? `${subPath}/${partners[partnerIndex - 1].slug}` : null, [partners, subPath, partnerIndex]);
  const nextPath = useMemo(() => partnerIndex + 1 < partners.length ? `${subPath}/${partners[partnerIndex + 1].slug}` : null, [partners, subPath, partnerIndex]);
  const history = useHistory();

  const handleUserKeyPress = useCallback((event) => {
    const { key } = event;
    if (key === 'ArrowLeft' && prevPath) {
      history.push(prevPath);
    } else if (key === 'ArrowRight' && nextPath) {
      history.push(nextPath);
    } else if (key === 'Escape') {
      history.push(subPath);
    }
  }, [
    prevPath,
    nextPath,
    history,
    subPath,
  ]);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  return (
    <div
      className={cn('partner-item', {
        visible: !!partnerSlug,
      })}
    >
      <div className="col-half left">
        <img
          src={partner.image}
          alt={partner.name}
        />
        <div className="founded">
          <div>Founded</div>
          <time>{partner.creationDate}</time>
        </div>
        <div className="vertical">
          <div>Vertical</div>
          <time>{partner.sector}</time>
        </div>
      </div>
      <div className="col-half right">
        <div className="top">
          <div className="close">
            <Link to="/partners" title="close">
              <span className="screen-reader">close</span>
              <span className="inner" />
            </Link>
          </div>
          <div className="progress-wrapper">
            <div
              className={cn('progress', {

              })}
              style={{
                width: `${100 / partners.length * (partnerIndex + 1)}%`,
              }}
            />
          </div>
        </div>
        <div className="center">
          <h3>{partner.name}</h3>
          <p className="description">
            {partner.description}
          </p>
          {partner.url ? (
            <div className="partner-url-outer">
              <a className="partner-url thin-spaced-text" href={partner.url} rel="noreferrer noopener">
                Visit website
                <span className="light-arrow" />
              </a>
            </div>
          ) : null}
        </div>
        <div className="bottom">
          <div className="navigation">
            {prevPath ? (
              <Link
                className="prev thin-spaced-text"
                to={prevPath}
              >
                previous
              </Link>
            ) : null}
            {nextPath ? (
              <Link
                className="last thin-spaced-text"
                to={nextPath}
              >
                next
                <span className="light-arrow" />
              </Link>
            ) : (
              <Link
                className="last thin-spaced-text"
                to={subPath}
              >
                close
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
