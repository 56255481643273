import React, { useMemo } from 'react';
import { Route } from 'react-router-dom';

const AppRoute = ({
  component: Component,
  layout: Layout,
  data,
  ...rest
}) => {

  const FinalLayout = useMemo(() => (Layout === undefined) ? (props) => <>{props.children}</> : Layout, [Layout]);

  return (
    <Route
      {...rest}
      render={props => (
        <FinalLayout>
          <Component data={data} {...props} />
        </FinalLayout>
      )} />
  );
}

export default AppRoute;
