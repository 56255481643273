import React from 'react';
import Position from '../../components/sections/position/Position';

export default function PositionView({
  data,
  ...rest
}) {
  return (
    <Position data={data} />
  );
}
