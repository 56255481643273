import React, { useState, useContext, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { AppContext } from '@yeutech/app-context';

export default function ContextIntlProvider({
  defaultLocale,
  i18nProvider,
  ...rest
}) {
  const { locale } = useContext(AppContext);
  const [messages, setMessages] = useState(i18nProvider());
  useEffect(() => {
    (async () => {
      const newMessages = await i18nProvider(locale);
      setMessages(newMessages);
    })();
  }, [locale, i18nProvider]);

  return (
    <IntlProvider
      defaultLocale={defaultLocale}
      locale={locale}
      messages={messages}
      {...rest}
    />
  );
}
