import React, { useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../../utils/SectionProps';
import JobsImage from '../../../assets/images/careers/1463771207-huge.jpg';
import JobsImageWebp from '../../../assets/images/careers/1463771207-huge.jpg';
import ImageSet from '../../elements/ImageSet';
import Faq from './Faq';
import JobItem from './JobItem';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Jobs = ({
  data: { careers },
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'Jobs section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'Jobs-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="containe">
        <div className="banner">
          <h2 className="Jobs-title title mt-0 mb-0">
            Jobs
          </h2>
          <ImageSet
            webp={JobsImageWebp}
            alt="Jobs"
            lazy={false}
            {...JobsImage}
          />
          <div className="darker-bg" />
        </div>
        <div className={innerClasses}>
          <div className="Jobs-content container">
            <ul className="jobs-list">
              {careers.map((job, i) => job.positionList.length > 0 && (
                <JobItem job={job} indice={i} key={job.id} />
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Faq />
    </section>
  );
}

Jobs.propTypes = propTypes;
Jobs.defaultProps = defaultProps;

export default Jobs;
