import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';

function checkConsented() {
  try {
    return JSON.parse(localStorage.getItem('cookie-consented'));
  } catch (e) {
    return false;
  }
}

function setConsented(consented) {
  localStorage.setItem('cookie-consented', JSON.stringify(consented));
}

const trackPage = (gaId, page) => {
  if (process.env.NODE_ENV !== 'test') {
    window.gtag('config', gaId, { 'page_title': document.title, page_path: page });
  }
};

const enableGa = (gaId, cb) => {
  if (!document.getElementById('gtm')) {
    const script = document.createElement('script');
    script.setAttribute('id', 'gtm');
    script.setAttribute('type', 'text/javascript');
    script.async = true;
    script.addEventListener('load', () => {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function () { window.dataLayer.push(arguments); }
      window.gtag('js', new Date());
      cb();
    });
    script.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${gaId}`);
    document.getElementsByTagName('head')[0].appendChild(script);
    return;
  }
  if (cb) {
    cb();
  }
}

export default function CookieConsent({
  gaId,
}) {
  const location = useLocation();

  const [value, setValue] = useState(undefined);

  const track = useCallback(() => {
    trackPage(gaId, location.pathname);
  }, [gaId, location]);

  useEffect(() => {
    const consented = checkConsented();
    setValue(consented);
    if (consented) {
      enableGa(gaId, track);
    }
  }, [gaId, track]);
  const updateValue = useCallback((v) => {
    setConsented(v);
    setValue(v);
    if (v) {
      enableGa(gaId, track);
    }
  }, [gaId, track]);
  if (value === true || value === false) {
    return null;
  }
  return (
    <div className="cookie-consent text-xs">
      <div className="container cookie-consent-inner">
        <p>
        We are using cookies and are processing personal data such as IP-addresses or web browser information to improve our website (Google{'\u00a0'}Analytics).
          <Link
            to="/imprint"
            className="further-information"
          >
            Further{'\u00a0'}information
          </Link>
        </p>
        <div
          className="cookie-consent-button-wrapper"
        >
          <button
            onClick={() => updateValue(false)}
            // href="#"
            className="button button-transparent"
          >
            Reject
          </button>
          <button
            onClick={() => updateValue(true)}
            // href="#"
            className="button button-primary"
          >
            I accept
          </button>
        </div>
      </div>
    </div>
  );
}
