import { AppContext } from '@yeutech/app-context';
import React, { useRef, useContext, useEffect, useState } from 'react';
const dateOptions = { weekday: undefined, year: 'numeric', month: 'long', day: 'numeric' };

export default function Tweets(props) {
  const [tweets, setTweets] = useState([]);
  const [error, setError] = useState(undefined);
  const { locale } = useContext(AppContext);
  const mountedRef = useRef(true)

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch('/tweets');
        const newTweets = await response.json();
        if (mountedRef.current) {
          setTweets(newTweets);
        }
      } catch (err) {
        const errorMessage = process.env.NODE_ENV !== 'production' ? err.message : 'Tweets are unavailable at this time';
        if (mountedRef.current) {
          setError(errorMessage);
        }
      }
    })();
    return () => {
      mountedRef.current = false;
    };
  }, [mountedRef]);

  if (!mountedRef.current) {
    return null;
  }
  if (error || !tweets.length) {
    return (
      <h5>
        {error || 'Loading...'}
      </h5>
    );
  }
  return (
    <ul className="tweets">
      {tweets?.map(({
        extended_entities: extendedEntities,
        ...tweet
      }) => {
        if (tweet.in_reply_to_user_id || tweet.retweeted_status) return null;
        const text = tweet.text.replace(/(https?:\/\/t.co\/\S+)$/, '');
        return (
          <li key={tweet.id} className="tweet-item">
            {(extendedEntities?.media[0] && extendedEntities?.media[0].type === 'photo') && (
              <a
                href={tweet.urls.link}
                target="_blank"
                rel="noreferrer noopener"
                className="tweet-item-image-link"
              >
                <img
                  className="tweet-item-image"
                  src={extendedEntities.media[0].media_url_https}
                  alt={tweet.text}
                  title={tweet.text}
                />
              </a>
            )}
            <div className="tweet-item-section">
              <time dateTime={tweet.created_at}>{new Date(tweet.created_at).toLocaleDateString(locale, dateOptions)}</time>
              <a href={tweet.urls.link} target="_blank" rel="noreferrer noopener">
                <h6>@{tweet.user.screen_name}</h6>
              </a>
              <a href={tweet.urls.link} target="_blank" rel="noreferrer noopener">
                <p>{text.trim()}</p>
              </a>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
