/**
* Capitalizes first letters of words in string.
* @param {string} str String to be modified
* @param {boolean=false} lower Whether all other letters should be lowercased
* @return {string}
  * @usage
  *   capitalize('fix this string');     // -> 'Fix This String'
*   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
*   capitalize('javaSCrIPT', true);    // -> 'Javascript'
*/
export const capitalize = (str, lower = false) => {
  return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
};


export const findAllExp = (regex, str) => {
  let m
  let res = [];
  while ((m = regex.exec(str)) !== null) {
    if (m.index === regex.lastIndex) {
      regex.lastIndex ++;
    }

    m.forEach((match, i) => {
      if (i === 1) {
        res.push(match);
      }
    });
  }
  return res;
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
