const en = {
  'hero.position.devOps': 'DevOps',
  'hero.position.CTO': 'CTO',
  'hero.description': `Partisan of the DevOps movement, I create state-of-the-art software solutions, designed to scale globally. As R&D experts in Web tech, I bring digital innovation added value to businesses.`,
  'underConstruction.title': 'Under construction',
  'underConstruction.visitBlog': 'Visit my blog',
  'twitter.latest': `Latest Tweets`,
  'twitter.down': `Tweets currently not available`,
  'header.name': `I'm {name}`,
  'header.intro': `I'm a {city} based`,
  'header.intro2': ' ',
  'nav.home': 'Home',
  'nav.about': 'About',
  'nav.resume': 'Resume',
  'nav.works': 'Works',
  'nav.testimonials': 'Testimonials',
  'nav.contact': 'Contact',
  'nav.blog': 'Blog',
  'nav.show': 'Show navigation',
  'nav.hide': 'Hide navigation',
  'about.me': 'About Me',
  'about.contactDetails': 'Contact Details',
  'about.profilePicture': '{name} profile picture',
  'about.getResume': 'Get Resume',
  'about.resumeNotReady': 'My resume is under redaction, please come back later.',
  'about.sendResume': 'Send the resume',
  'about.chooseResume': 'Choose a resume',
  'about.or': 'Or',
  'about.resumeNotAvailable': `This resume is not available at this moment`,
  'about.resumeSendSuccess': `The resume has just been sent you.`,
  'about.resumeSendFail': `An error occurred while sending the resume. I have just been informed. In the meantime, please use the contact form.`,
  'about.resumeSendFailTooManyRequest': `A resume has already been sent to you recently, please try again later.`,
  'about.resumeSendFailTooManyRequestUseForm': 'For any request, the contact form remains at your disposal.',
  'contact.title': 'Get In Touch',
  'contact.subtitle': `Can’t find what you need? Any questions? I am here to help. For all enquiries, please use the form bellow.`,
  'contact.submit': 'Submit',
  'contact.error': 'Something wrong happened while sending your email',
  'contact.success': 'Your message was sent, thank you!',
  'contact.address': 'Address',
  'contact.name': 'Name',
  'contact.email': 'Email',
  'contact.mobile': 'Mobile phone',
  'contact.subject': 'Subject',
  'contact.message': 'Message',
  'contact.3charsMin': '3 characters minimum',
  'contact.5charsMin': '5 characters minimum',
  'contact.0or5charsMin': 'Either 0 or 5 characters minimum',
  'contact.0or8charsMin': 'Either 0 or 8 characters minimum',
  'work.skillTitle': 'This is not an exhaustive list, just a fancy list',
  'work.work': 'Work',
  'work.skills': 'Skills',
  'work.education': 'Education',
  'work.portfolio': 'Check Out Some of My Works.',
  'pdf.profile': 'Profile',
  'pdf.summary': 'Summary',
  'pdf.workExperience': 'Work experience',
  'pdf.education': 'Education',
  'pdf.position.cto': 'Chief Technology Officer',
  'pdf.position.cto.short': 'CTO',
  'pdf.position.devops': 'DevOps',
  'pdf.technologySkills': 'Technology skills',
  'pdf.languages': 'Languages',
  'pdf.skills': 'Skills',
  'pdf.interests': 'Interests',
  'pdf.contactInformation': 'Contact',
  'pdf.accomplishments': 'Accomplishments',
  'pdf.aboutMe': 'About me',
  'testimonials.title': 'Client Testimonials',
  'testimonials.pcn': `Dimitri has a lot of energy, finds ingenious solutions that meet the needs of the project with quality and respecting deadlines. In addition, his broad knowledge of technologies allows him to make the right technical choices. I highly recommend.`,
  'testimonials.cdg': `Dimitri masters many technologies covering the entire field of DevOps. He is able to manage projects in their entirety. He knows how to develop his tested code as well as how to set up the CI / CD pipeline to deploy it on infrastructures that he has also set up from A to Z. He has developed numerous tools and libraries allowing him to create quickly ambitious projects. It is very adaptive and is able to integrate new APIs and external services without delay. I highly recommend it.`,
  'testimonials.ajt': `Dimitri has been a mentor to me for many years. His motivation is a great source of inspiration, Dimitri only produces high quality work. With in-depth and diversified knowledge, and an unparalleled work ethic, Dimitri is a sure bet!`,
  'testimonials.agd': `Endowed with an unfailing capacity for concentration, Dimitri is an incredible work force. He perfectly masters agile methodologies and brings a solid technical expertise in order to reach the state of the art for all the projects he leads. I have personally and professionally learned a lot from him, and his teachings still inspire me in my daily work. I recommend it with no hesitation.`,
};

export default en;
