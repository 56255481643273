import { AppContext } from '@yeutech/app-context';
import React, { useContext } from 'react';
const dateOptions = { weekday: undefined, year: 'numeric', month: 'long', day: 'numeric' };

export default function ImprintAddress({
  company
}) {
  const incorporationDate = new Date(company.creationDate);
  const { locale } = useContext(AppContext);

  return (
    <div
      className="imprint-address text-xxs"
    >
      <h2>Imprint</h2>
      <h6 className="text-xxs">{company.legalName}</h6>
      <address className="text-xxs">
        <a
          href="https://goo.gl/maps/4PvMLhpFSs5NnA7s7"
          target="_blank"
          rel="noreferrer noopener"
        >
          <div>
            {company.streetAddressLine1}
          </div>
          <div>
            {company.streetAddressLine2}
          </div>
          <div>
            {company.country}
          </div>
        </a>
        {/*<div>*/}
        {/*  T. <a href={`tel:+${company.telephone}`}>+{company.telephone}</a>*/}
        {/*</div>*/}
        <div>
          <a href={`mailto:${company.email}`}>{company.email}</a>
        </div>
        <div>
          Incorporated on <time dateTime={incorporationDate}>{incorporationDate.toLocaleDateString(locale, dateOptions)}</time>,
        </div>
        <div>
          Registration N°{company.registrationId}
        </div>
      </address>
    </div>
  );
}
