import React, { useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../../utils/SectionProps';
import ExpertsImage from '../../../assets/images/careers/work-731198_1920-xl.jpg';
import ExpertsImageWebp from '../../../assets/images/careers/work-731198_1920-xl.jpg?format=webp';

import ImageSet from '../../elements/ImageSet';
import ExpertContactForm from './ExpertContactForm';
const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Experts = ({
  data,
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'Experts section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'Experts-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div>
        <div className="banner">
          <h2 className="Experts-title title mt-0 mb-0">
            Kopaxgroup Associates
          </h2>
          <ImageSet
            webp={ExpertsImageWebp}
            alt="Experts"
            lazy={false}
            {...ExpertsImage}
          />
          <div className="darker-bg" />
        </div>
      </div>
      <div className={innerClasses}>
        <div className="Experts-content">
          <div className="container-xs">
            <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Senior Experts Matters to Kopaxgroup.
            </h3>
            <p className="reveal-from-bottom" data-reveal-delay="300">
              Fast growing ventures seek key skills to become disruptive. Senior
              industry experts can help to reduce time to market delays for clients.
            </p>
          </div>
        </div>
      </div>
      <div className="Experts-content">
        <div className="Experts-city container reveal-from-bottom" data-reveal-delay="400">
          <p className="m-0 mb-32">
            After a crisis, comes the next crisis.
          </p>
        </div>
        <div className="container-sm experts-list-wrapper list-wrapper">
          <div className="experts-list-left list-left reveal-from-bottom" data-reveal-delay="600">
            <blockquote>
              Globalisation has peaked, transformation is accelerating and economic rivalry increasing. The
              pandemic crisis created a big economic and social crisis. We’re innovating and help
              customers building future cloud based business models and employment.
              At Kopaxgroup, we welcome senior experts, keen to associate with new growth ventures.
            </blockquote>
          </div>
          <div className="experts-list-right list-right text-sm">
            <ul>
              <li className="reveal-from-bottom" data-reveal-delay="800">
                <h6 className="text-xs">What's an expert.</h6>
                <p className="text-xxs">
                  Experts are confirmed enterprise professionals with a rich industry, technology, legal of financial engineering, governance, risk and fraud with market know-how. Experts can come from any domains.
                  Corporate restructuring, mergers and acquisitions, corporate financing, board of director advisors, senior sales or deep process expertise. The experts we are looking for ideally are confirmed opinion leaders with active publications and blog poster. We value a rich international career of more than one or two decades with foreign culture and language skills.
                </p>
              </li>
              <li className="reveal-from-bottom" data-reveal-delay="1000">
                <h6 className="text-xs">What kind of expert we are looking for.</h6>
                <p className="text-xxs">
                  Experts we are looking for are confirmed opinion leaders with active or past publications and regular blogposts. We value a rich international career of more than one or two decades and foreign culture and multi-language skills.
                  We seek horizontal generalists and vertical deep industry insiders, Awareness of the global competitive landscape and how to best provoke disruptive transformation. Multiple domains such a legacy IT, manufacturing know-how, supply chain,...
                </p>
              </li>
              <li className="reveal-from-bottom" data-reveal-delay="1200">
                <h6 className="text-xs">What you can expect from us.</h6>
                <p className="text-xxs">
                  Kopaxgroup combine investors with potential seed, early and growth investment opportunities where senior experts may become team many global connections of both business and deep technology network. Senior experts will be supported by deep tech Cloud and internet experts. Depending on client demand we may anonymously propose senior and junior experts to assemble agile teams for different start ups, their angels, seed and growth investors. We also can supply Blog platform services to selected experts.
                </p>
              </li>
              <li className="reveal-from-bottom" data-reveal-delay="1400">
                <h6 className="text-xs">What you must bring to us.</h6>
                <p className="text-xxs">
                  Besides fully documenting your industry expertise and geographical availability. We also expect to know your availability. For pertinent publications the right to, to republish content under the name of expert and Kopaxgroup. We also, when a prospective client confirm interest for an expert profile to participate in the commercial proposal process.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div
          className="invert-color has-bg-color section-inner"
        >
          <div className="container-xs">
            <ExpertContactForm />
          </div>
        </div>
      </div>
    </section>
  );
}

Experts.propTypes = propTypes;
Experts.defaultProps = defaultProps;

export default Experts;
