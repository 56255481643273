import React, { useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../../utils/SectionProps';
import PrivacyPolicy from './PrivacyPolicy';
import ImprintAddress from './ImprintAddress';
const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Imprint = ({
  data,
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'Privacy section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'Privacy-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="Privacy-content">
            <ImprintAddress company={data.company} />
            <PrivacyPolicy />
          </div>
        </div>
      </div>
    </section>
  );
}

Imprint.propTypes = propTypes;
Imprint.defaultProps = defaultProps;

export default Imprint;
