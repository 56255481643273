import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import {
  useParams,
} from 'react-router-dom';
import ApplyPositionForm from './ApplyPositionForm';
import LocationOnIcon from '@material-ui/icons/LocationOn';
export default function PositionApply({
  data: { company, careers },
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) {
  const { position, location, type, title } = useParams();
  const positionCategory = useMemo(() => careers.find((c) => c.id === position), [careers, position]);
  const job = useMemo(() => positionCategory.positionList.find((p) => p.location.toLowerCase() === location.toLowerCase() && p.type.toLowerCase() === type.toLowerCase() && p.title.toLowerCase() === title.toLowerCase()), [positionCategory, location, type, title]);

  const outerClasses = classNames(
    'PositionApply section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'PositionApply-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className={innerClasses}>
        <div className="PositionApply-content reveal-from-bottom container" data-reveal-delay="200">
          <header>
            <div className="title-wrapper">
              <h6>
                {job.title} application
              </h6>
              <div className="text-xxs location"><LocationOnIcon fontSize="small" />{job.location}</div>
              <p className="text-xxs description">{job.description}</p>
            </div>
          </header>
          <div className="text-xs">
            <ApplyPositionForm
              company={company}
              job={job}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
