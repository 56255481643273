import React from 'react';
import { Link } from 'react-router-dom';

export default function InvestorFooter(props) {
  return (
    <div className="investor-footer invert-color has-bg-color">
      <div className="container text-xxs">
        <div className="text-notice">
          <div className="text-underline">
            CAUTION CONCERNING FORWARD LOOKING STATEMENTS
          </div>
          <div>
            Kopaxgroup Investor Relations website at{' '}
            <Link
              to="/investors"
              className="text-color-primary"
            >
              www.kopaxgroup.com/investors
            </Link>
            {' '}as well as Kopaxgroup's social media accounts,
            contain a significant amount of information about Kopaxgroup,
            including financial and other information for investors. Kopaxgroup
            encourages investors to visit these websites from time to
            time, as information is updated and new information is
            posted.
          </div>
        </div>
      </div>
    </div>
  )
}
