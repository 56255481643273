import { fr, enUS } from 'date-fns/locale'
import defaultMessages from './en';

export const locales = { en: enUS, fr };

let browserLocal = navigator.languages
  ? navigator.languages[0]
  : (navigator.language || navigator.userLanguage);

if (browserLocal && browserLocal.includes('-')) {
  browserLocal = browserLocal.split('-')[0];
}

export const detectedLocale = browserLocal;

/**
 * @public
 * @name defaultLocale
 * @description
 * Configure the default application locale country (default to `en`)
 * @type {string}
 */
export const defaultLocale = 'en';

export const i18nProvider = (locale = defaultLocale) => {
  if (locale === defaultLocale) {
    // initial call, must return synchronously
    return defaultMessages;
  }
  if (locale === 'fr') {
    return import('./fr.js').then((messages) => messages.default);
  }
};

