import React from 'react';
import Careers from '../../components/sections/careers/Careers';

export default function CareersView({
  data,
  ...rest
}) {
  return (
    <Careers data={data} />
  );
}
