import React from 'react';
import PositionApply from '../../components/sections/position/PositionApply';

export default function PositionApplyView({
  data,
  ...rest
}) {
  return (
    <PositionApply data={data} />
  );
}
