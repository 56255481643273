import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { SectionProps } from '../../../utils/SectionProps';
import CareersImage from '../../../assets/images/business/skyscraper-1149478_1920-xl.jpg';
import CareersImageWebp from '../../../assets/images/business/skyscraper-1149478_1920-xl.jpg?format=webp';
import ExpertsImage from '../../../assets/images/careers/work-731198_1920-xl.jpg';
import ExpertsImageWebp from '../../../assets/images/careers/work-731198_1920-xl.jpg?format=webp';

import ImageSet from '../../elements/ImageSet';
const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Careers = ({
  data,
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'Careers section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'Careers-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div>
        <div className="banner">
          <h2 className="Careers-title title mt-0 mb-0">
            Careers & Affiliation
          </h2>
          <ImageSet
            webp={CareersImageWebp}
            alt="Careers"
            lazy={false}
            {...CareersImage}
          />
          <div className="darker-bg" />
        </div>
      </div>
      <div className={innerClasses}>
        <div className="Careers-content">
          <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
            We further your career opportunities
          </h3>
        </div>
      </div>
      <div className="Careers-content">
        <div className="careers-city container reveal-from-bottom" data-reveal-delay="400">
          <p className="m-0 mb-32">
            We value entrepreneurship and encourage subject matter ownership to define, assemble and deliver
            custom assembled operational solutions for investors and entrepreneurs. <br />
            Your experts skills and opinions matter to Kopax.
          </p>
          <Link
            to="/experts"
            className="button button-transparent"
          >
            Entrepreneurs & Experts
          </Link>
          <ImageSet
            webp={ExpertsImageWebp}
            alt="Entrepreneur & Experts"
            {...ExpertsImage}
            sizes="(min-width: 1200px) 1128px, calc(94.09vw + 18px)"
          />
          <div className="darker-bg darker-bg-strong" />
        </div>
        <div className="container-sm jobs-list-wrapper list-wrapper">
          <div className="jobs-list-left list-left reveal-from-bottom" data-reveal-delay="600">
            <blockquote>
              At Kopaxgroup, we are all working towards a more sustainable society for the common good within our organization and with our affiliates around the world. We’re building a future where data can be exploited to serve people, create value, and improve quality of life.
              No matter where you're based, you’ll be part of a group of people working together on creative solving on mission-critical problems. There’s a role here for you to pave the way to innovation. Here, you’ll do more than join something, you’ll add something.
            </blockquote>
          </div>
          <div className="jobs-list-right list-right text-sm">
            <ul>
              <li className="reveal-from-bottom" data-reveal-delay="800">
                <h6 className="text-xs">What to expect during a Kopax delivery journey?</h6>
                <p className="text-xxs">
                  Our affiliates, and partners, are skilled
                  contractors, participating in the solution specification and delivery process. We use continuous
                  improvement and agile interactive working methods.
                </p>
              </li>
              <li className="reveal-from-bottom" data-reveal-delay="1000">
                <h6 className="text-xs">An experienced team with international reach.</h6>
                <p className="text-xxs">
                  We help European and Asian growth
                  focused ventures. Together with investors and all stakeholders we creat lean and agile Cloud
                  based organisations with the functionality to sustain market leadership ambitions.
                </p>
              </li>
              <li className="reveal-from-bottom" data-reveal-delay="1200">
                <h6 className="text-xs">Opportunity to take ownership.</h6>
                <p className="text-xxs">
                  Our technical and process experts, forced by the pandemic
                  crisis ourselves, to go digital. Digitized business processes don’t endanger sales, logistics
                  and delivery processes. Jointly with clients on and offshore support teams, co-owned and co-
                  invested support and R&amp;D spin-outs opportunities emerge.
                </p>
              </li>
              <li className="reveal-from-bottom" data-reveal-delay="1400">
                <h6 className="text-xs">Digital Teambuilding.</h6>
                <p className="text-xxs">
                  We live and breath digital, ourselves and help our associates and
                  clients to build trusted personal relationships beyond business driving value add and human
                  relations.
                </p>
              </li>
            </ul>
            <div className="buttons-wrapper">
              <Link
                to="/careers/jobs"
                className="button button-dark"
                rel="noreferrer noopener"
              >
                View Jobs
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Careers.propTypes = propTypes;
Careers.defaultProps = defaultProps;

export default Careers;
