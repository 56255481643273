import React from 'react';
import { Link, useParams } from 'react-router-dom';

export default function PositionTable({
  positionList,
}) {
  const { position: positionId } = useParams();
  return (
    <table className="table-inverse table-select">
      <thead>
        <tr>
          <th>Position</th>
          <th width="100px">Job Type</th>
          <th>Location</th>
        </tr>
      </thead>
      <tbody>
        {positionList.map((position) => (
          <tr
            key={position.title + position.type + position.location}
            title={`View details for ${position.type} ${position.title} position in ${position.location}`}
          >
            <td>
              <Link to={`/careers/jobs/${positionId}/${position.location}/${position.type}/${position.title}`}>
                {position.title}
              </Link>
            </td>
            <td>
              <Link to={`/careers/jobs/${positionId}/${position.location}/${position.type}/${position.title}`}>
                {position.type}
              </Link>
            </td>
            <td>
              <Link to={`/careers/jobs/${positionId}/${position.location}/${position.type}/${position.title}`}>
                {position.location}
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
