import AppContextProvider from '@yeutech/app-context';
import React, { useEffect, useRef, useState } from 'react';
import { Switch, useLocation } from 'react-router-dom';

import ContextIntlProvider from './components/ContextIntlProvider';
import CookieConsent from './components/sections/cookieConsent/CookieConsent';

import { env } from './config';
import LayoutDefault from './layouts/LayoutDefault';
import { defaultLocale, detectedLocale, i18nProvider, locales } from './translations/i18nProvider';
import AppRoute from './utils/AppRoute';
import { preloadImage } from './utils/images';
import ScrollReveal from './utils/ScrollReveal';
import Careers from './views/Careers';
import Experts from './views/Experts';
import Home from './views/Home';
import Imprint from './views/Imprint';
import Investors from './views/Investors';
import Jobs from './views/Jobs';
import Media from './views/Media';
import Partners from './views/Partners';
import Position from './views/Position';
import PositionApply from './views/PositionApply';
import PositionSheet from './views/PositionSheet';

const { gaId } = env;

export default function App(props) {
  const [data, setData] = useState(undefined);
  const [locale, setLocale] = useState(detectedLocale);
  const childRef = useRef();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch('/initialData.json');
        const fetchedData = await response.json();
        setData(fetchedData);
        fetchedData.companies.forEach((partner) => ['logo', 'image'].forEach((type) => preloadImage(partner[type])));
      } catch (err) {
        alert('Something went wrong, please come back later');
      }
    })();
  }, []);

  useEffect(() => {
    if (data) {
      document.body.classList.add('is-loaded');
      childRef.current.init();
    }
  }, [location, data]);

  if (!data) {
    return null;
  }
  return (
    <AppContextProvider
      locale={locale}
      locales={locales}
      setLocale={setLocale}
    >
      <ContextIntlProvider
        defaultLocale={defaultLocale}
        i18nProvider={i18nProvider}
      >
        <ScrollReveal
          ref={childRef}
          children={() => (
            <Switch>
              <AppRoute
                exact
                path="/"
                component={Home}
                layout={LayoutDefault}
              />
              <AppRoute
                path="/partners"
                component={Partners}
                layout={LayoutDefault}
                data={data}
              />
              <AppRoute
                exact
                path="/media"
                component={Media}
                layout={LayoutDefault}
              />
              <AppRoute
                exact
                path="/imprint"
                component={Imprint}
                layout={LayoutDefault}
                data={data}
              />
              <AppRoute
                exact
                path="/investors"
                component={Investors}
                layout={LayoutDefault}
                data={data}
              />
              <AppRoute
                exact
                path="/careers"
                component={Careers}
                layout={LayoutDefault}
                data={data}
              />
              <AppRoute
                exact
                path="/careers/jobs"
                component={Jobs}
                layout={LayoutDefault}
                data={data}
              />
              <AppRoute
                exact
                path="/experts"
                component={Experts}
                layout={LayoutDefault}
                data={data}
              />
              <AppRoute
                exact
                path="/careers/jobs/:position"
                component={Position}
                layout={LayoutDefault}
                data={data}
              />
              <AppRoute
                exact
                path="/careers/jobs/:position/:location/:type/:title"
                component={PositionSheet}
                layout={LayoutDefault}
                data={data}
              />
              <AppRoute
                exact
                path="/careers/jobs/:position/:location/:type/:title/apply"
                component={PositionApply}
                layout={LayoutDefault}
                data={data}
              />
            </Switch>
          )}
        />
        <CookieConsent gaId={gaId} />
      </ContextIntlProvider>
    </AppContextProvider>
  );
}
