import React from 'react';
import Imprint from '../../components/sections/imprint/Imprint';

export default function ImprintView({
  data,
  ...rest
}) {
  return (
    <Imprint data={data} />
  );
}
