import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  useLocation,
} from 'react-router-dom';
import { validateEmail } from '../../../utils/string';
import Profile from '../../elements/Profile';
import Input from '../../form/Input';
import avatar from '../investors/alexanderkopriwa.jpeg';

export default function ExpertContactForm({
  name = 'expert-contact-form',
}) {
  const [pristine, setPristine] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(undefined);
  const [validation, setValidation] = useState({});
  const location = useLocation();
  const handleValidate = useCallback((e) => {
    setPristine(false);
    let newValidation = {};
    const {
      firstName,
      lastName,
      email,
      company,
      message,
    } = e.currentTarget;

    const values = {
      firstName: firstName.value.trim(),
      lastName: lastName.value.trim(),
      email: email.value.trim(),
      company: company.value.trim(),
      message: message.value.trim(),
    };
    let shouldValidateAll = e.target.name === name;
    if (!values.firstName.length) {
      newValidation.firstName = 'First name is missing';
    } else if (values.firstName.length < 2) {
      newValidation.firstName = 'First name should be at least 2 characters';
    }
    if (!values.lastName.length) {
      newValidation.lastName = 'Last name is missing';
    } else if (values.lastName.length < 2) {
      newValidation.lastName = 'Last name should be at least 2 characters';
    }
    if (!values.email.length) {
      newValidation.email = 'Email is missing';
    } else if (!validateEmail(values.email)) {
      newValidation.email = 'Email is invalid';
    }
    if (!values.company.length) {
      newValidation.company = 'Company is missing';
    } else if (values.company.length < 2) {
      newValidation.company = 'Company should be at least 2 characters';
    }
    if (!values.message.length) {
      newValidation.message = 'Message cannot be empty';
    } else if (values.message.length < 100) {
      newValidation.message = 'Message should be at least 100 characters';
    } else if (values.message.length > 2000) {
      newValidation.message = 'Message cannot contain more than 2000 characters';
    }
    if (shouldValidateAll) {
      setValidation(newValidation);
    } else {
      const clearedValidation = {};
      Object.keys(validation).forEach((v) => {
        if (newValidation[v] && validation[v]) {
          clearedValidation[v] = newValidation[v];
        }
      });
      setValidation({
        ...clearedValidation,
        ...(newValidation[e.target.name] ? { [e.target.name]: newValidation[e.target.name] } : {}),
      });
      return validation;
    }

    return newValidation;
  }, [setPristine, validation, name]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setError(undefined);
    (async () => {
      try {
        const {
          firstName,
          lastName,
          email,
          company,
          message,
        } = e.currentTarget;
        const values = {
          firstName: firstName.value.trim(),
          lastName: lastName.value.trim(),
          email: email.value.trim(),
          company: company.value.trim(),
          message: message.value.trim(),
        };
        const v = handleValidate(e);
        if (Object.keys(v).length === 0) {
          const formData = new FormData();
          Object.keys(values).forEach((key) => formData.append(key, typeof values[key] === 'object' ? JSON.stringify(values[key]) : values[key]));
          setSubmitting(true);
          const response = await fetch('/experts/apply', {
            method: 'POST',
            body: formData,
          });
          if (response.status === 200) {
            setSubmitted(true);
          } else if (response.status === 412) {
            setError(`You have already received our experts partnership application from by email.`);
          } else {
            setError(`We couldn't submit your experts partnership application, please try again later.`);
          }
        }
      } catch (err) {
        setError(process.env.NODE_ENV === 'production' ? `We couldn't submit your experts partnership application, please try again later.` : err.message);
        process.env.NODE_ENV !== 'production' && console.error(err);
      }
      setSubmitting(false);
    })();
  }, [handleValidate]);

  return (
    <form
      name={name}
      action={location.pathname}
      onSubmit={handleSubmit}
      method="post"
      encType="multipart/form-data"
      onChange={handleValidate}
      className="expert-contact-form text-xs"
    >
      <h3>Contact our partnership manager</h3>
      <Profile
        firstName="Alexander"
        lastName="Kopriwa"
        position="Partnership manager"
        linkedIn="https://www.linkedin.com/in/kopriwa/"
        avatar={avatar}
      />
      <fieldset>
        {submitted ? (
          <>
            <h5>Please check your email we sent you a partner application form</h5>
            <p className="text-xxs description">
              Fill the experts partnership application form and return it in attachment by replying to the email.
              We try to answer to all applications in the limit of our capacities and priorities.
              If you do not received an answer after 3 months, you can consider your application to be refused.
              Kopaxgroup will keep your information for at least 2 years, after that time, you are welcome to submit a new application.
            </p>
          </>
        ) : (
          <>
            <legend>Personal information</legend>
            <div className="form-inner">

              <div
                className="half left"
              >
                <Input
                  name="firstName"
                  label="First name"
                  error={validation.firstName}
                  required
                  readOnly={submitting}
                />
                <Input
                  name="lastName"
                  label="Last name"
                  error={validation.lastName}
                  required
                  readOnly={submitting}
                />
              </div>
              <div
                className="half right"
              >
                <Input
                  name="email"
                  label="Email"
                  type="email"
                  error={validation.email}
                  required
                  readOnly={submitting}
                />
                <Input
                  name="company"
                  label="Company"
                  type="text"
                  error={validation.company}
                  required
                  readOnly={submitting}
                />
              </div>
              <div className="full-width">
                <Input
                  label="Let us know why you are interested to collaborate with us:"
                  name="message"
                  type="textarea"
                  maxLength="2000"
                  minLength="100"
                  required
                  error={validation.message}
                />
                <div
                  className={classNames('form-error', 'text-color-error', {
                    visible: error,
                  })}
                >
                  {error}
                </div>
                <button
                  name="submit"
                  className="button mt-8"
                  type="submit"
                  disabled={pristine || submitting}
                >
                  Submit
                  {submitting && (<>{'\xa0'}<CircularProgress size={19} /></>)}
                </button>
              </div>
            </div>
          </>
        )}
      </fieldset>
    </form>
  );
}
