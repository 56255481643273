import React from 'react';
import classNames from 'classnames';
import FaqItem from './FaqItem';


const items = [
  {
    question: 'How can I contact you?',
    answer: (
      <p>
        We only accept job applications via the online form. However, if you have any questions that have not been answered by our FAQs, do not hesitate to contact us at <a href="mailto:recruitment@kopaxgroup.com">recruitment@kopaxgroup.com</a>
      </p>
    ),
  },
  {
    question: 'What benefits do you offer?',
    answer: (
      <p>
        If you want to know more about the specific benefits that each company in our network can offer you, please refer to their website.
      </p>
    ),
  },
  {
    question: 'How are Kopaxgroup recruitment decisions made?',
    answer: (
      <p>
        All our decisions are made as a team: everyone who has been involved in the recruiting process - the managers, the recruiter, the team and so on - have a say in the final decision.
      </p>
    ),
  },
  {
    question: 'Will I get a chance to find out more about the team?',
    answer: (
      <p>
        Yes, the Recruiter who conducts the HR phone screening is closely aligned with the hiring manager and can answer any general questions you may have. Furthermore, in the final round, you will get the opportunity to meet the team during the face-to-face interview.
      </p>
    ),
  },
  {
    question: 'What questions can I expect to be asked?',
    answer: (
      <p>
        The recruiters will assess your motivation, general competencies, and cultural fit. Furthermore, they will discuss key topics with you: such as salary expectations, potential start dates as well as relocation and visa topics. The interviews with the manager or the team typically include more specific questions on your technical expertise and whether your knowledge is relevant to our culture.
      </p>
    ),
  },
  {
    question: 'How long are the interviews?',
    answer: (
      <p>
        The HR phone screening will last around 30 minutes. The subsequent interviews with the hiring managers can take up to an hour and a half.
      </p>
    ),
  },
  {
    question: 'How many interviews will I have?',
    answer: (
      <p>
        The recruiting process can be different for each position that you apply for. A typical process consists of: A phone/skype interview, a case study or work example, an interview with the manager and an interview with the team. Your recruiter contact will explain the whole process to you and share the LinkedIn profiles of the people you will meet during the interviews.
      </p>
    ),
  },
  {
    question: 'What should my resume look like?',
    answer: (
      <p>
        Make it clear, concise and logical to read and emphasize relevant experience. If you don’t have time, you can also apply with your LinkedIn Profile.
      </p>
    ),
  },
  {
    question: 'Is there a deadline for my application?',
    answer: (
      <p>
        As long as the position is accessible on our website you can apply for it.
      </p>
    ),
  },
  {
    question: 'What is the difference between Kopaxgroup and the companies within the Kopaxgroup network?',
    answer: (
      <p>
        We incubate and invest in companies from our Kopaxgroup HQ. Our network of companies are based around the world; each one is autonomous and has their own company culture.
      </p>
    ),
  },
];


const left = [0, Math.floor(items.length / 2) - 1];
const right = [Math.floor(items.length / 2), items.length - 1];

export default function Faq({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) {

  const outerClasses = classNames(
    'Faq section left-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'Faq-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <h2 className="text-color-dark mt-0">FAQ</h2>
          <div className="Faq-content">
            <div className="half">
              {items.map((item, i) => (i >= left[0] && i <= left[1]) && (
                <FaqItem key={item.question} {...item} />
              ))}
            </div>
            <div className="half">
              {items.map((item, i) => (i >= right[0] && i <= right[1]) && (
                <FaqItem key={item.question} {...item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
