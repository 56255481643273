import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../../utils/SectionProps';
import ImageSet from '../../elements/ImageSet';
import Tweets from './Tweets';
import mediaImageXl from '../../../assets/images/media/glasses-934922-xl.jpg';
import mediaImageXlWebp from '../../../assets/images/media/glasses-934922-xl.jpg?format=webp';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Media = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'Media section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'Media-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="">
        <div className="banner">
          <h2 className="media-title title mt-0 mb-0">
            Media
          </h2>
          <div className="darker-bg" />
          <ImageSet
            lazy={false}
            webp={mediaImageXlWebp}
            alt="Media"
            {...mediaImageXl}
          />
        </div>
        <div className={innerClasses}>
          <div className="Media-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Social <span className="text-color-primary">Media</span>
            </h1>
            <div className="container-sm">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                Follow us on Twitter and LinkedIn for all the latest news.
              </p>
              <a
                href="https://www.twitter.com/kopaxgroup"
                className="button button-dark"
                rel="noreferrer noopener"
              >
                twitter
              </a>
              {' '}
              <a
                href="https://www.linkedin.com/company/kopax-group-ltd"
                className="button button-dark"
                rel="noreferrer noopener"
              >
                linkedin
              </a>
              <Tweets />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Media.propTypes = propTypes;
Media.defaultProps = defaultProps;

export default Media;
