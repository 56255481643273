import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { SectionProps } from '../../../utils/SectionProps';
import PositionImage from '../../../assets/images/careers/1463771207-huge.jpg';
import PositionImageWebp from '../../../assets/images/careers/1463771207-huge.jpg?format=webp';
import ImageSet from '../../elements/ImageSet';
import Faq from '../jobs/Faq';
import PositionTable from './PositionTable';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Position = ({
  data: { careers },
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const { position } = useParams();
  const career = careers.find((item) => item.id === position);

  const outerClasses = classNames(
    'Position section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'Position-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="">
        <div className="banner">
          <h2 className="Position-title title mt-0 mb-0">
            {position[0].toUpperCase()}{position.slice(1)}
          </h2>
          <ImageSet
            webp={PositionImageWebp}
            alt="Position"
            {...PositionImage}
          />
          <div className="darker-bg" />
        </div>
        <div className={innerClasses}>
          <div className="Position-content reveal-from-bottom container" data-reveal-delay="200">
            <div className="quote-wrapper">
              <blockquote>
                {career.quote}
              </blockquote>
            </div>
            <div className="text-xxs text-color-low description-wrapper">
              <p>
                {career.description}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="position-table-wrapper section-inner">
        <div className="container">
          <h2 className="text-color-dark mt-0">Open Positions</h2>
          <PositionTable positionList={career.positionList} />
        </div>
      </div>
      <Faq />
    </section>
  );
}

Position.propTypes = propTypes;
Position.defaultProps = defaultProps;

export default Position;
