import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import React, { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { validateEmail } from '../../../utils/string';
import Profile from '../../elements/Profile';
import Input from '../../form/Input';
import avatar from './alexanderkopriwa.jpeg';


function InvestorNewsForm({
  name = 'investors-news',
}) {
  const [submitting, setSubmitting] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [pristine, setPristine] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [validation, setValidation] = useState({});

  const { pathname } = useLocation();

  const handleValidate = useCallback((e) => {
    setPristine(false);
    let newValidation = {};
    const {
      email,
    } = e.currentTarget;

    const values = {
      email: email.value.trim(),
    };
    let shouldValidateAll = e.target.name === name;
    if (!values.email.length) {
      newValidation.email = 'Email is missing';
    } else if (!validateEmail(values.email)) {
      newValidation.email = 'Email is invalid';
    }
    if (shouldValidateAll) {
      setValidation(newValidation);
    } else {
      const clearedValidation = {};
      Object.keys(validation).forEach((v) => {
        if (newValidation[v] && validation[v]) {
          clearedValidation[v] = newValidation[v];
        }
      });
      setValidation({
        ...clearedValidation,
        ...(newValidation[e.target.name] ? { [e.target.name]: newValidation[e.target.name] } : {}),
      });
      return validation;
    }

    return newValidation;
  }, [setPristine, validation, name]);
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setError(undefined);
    (async () => {
      try {
        const {
          email,
        } = e.currentTarget;
        const values = {
          email: email.value.trim(),
        };
        const v = handleValidate(e);
        if (Object.keys(v).length === 0) {
          setSubmitting(true);
          const response = await fetch('/newsletter/register/investor', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: new Headers({
              'Content-Type': 'applicatiojn/json',
            })
          });
          if (response.status !== 200) {
            setSubmitted(true);
          } else if(response.status === 412) {
            setError(`Your email is already registered.`);
          } else {
            setError(`We couldn't register your email, please try again later.`);
          }
        }
      } catch (err) {
        setError(process.env.NODE_ENV === 'production' ? `We couldn't register your email, please try again later.` : err.message);
        process.env.NODE_ENV !== 'production' && console.error(err);
      }
      setSubmitting(false);
    })();

  }, [handleValidate]);
  return (
    <form
      name={name}
      action={pathname}
      onSubmit={handleSubmit}
      onChange={handleValidate}
      method="post"
    >
      <h3>Stay connected with Investor news</h3>
      {submitted ? (
        <p className="text-xs">
          Thank you for your interest in our company. You will be notified of future investment opportunities in our companies.
        </p>
      ) : (
        <>
          <p>
            Sign up and stay informed on investments opportunities
          </p>
          <label
            htmlFor="email"
            className="screen-reader"
          >
            Email
          </label>
          <Input
            name="email"
            type="email"
            placeholder="your@email.com"
            error={validation.email}
            required
            readOnly={submitting}
          >
            <button
              name="submit"
              type="submit"
              disabled={pristine || submitting}
              className="button button-primary"
            >
              {submitting ? (<>{'\xa0'}<CircularProgress size={19} /></>) : 'Sign up'}
            </button>
          </Input>
          <div
            className={classNames('form-error', 'text-color-error', 'text-xxs', {
              visible: error,
            })}
          >
            {error}
          </div>
        </>
      )}
    </form>
  );
}

export default function InvestorNews(props) {
  return (
    <div className="has-bg-color investor-news">
      <div className="investor-news-inner container">
        <div className="left half">
          <InvestorNewsForm
          />
        </div>
        <div className="right half">
          <Profile
            firstName="Alexander"
            lastName="Kopriwa"
            position="President of Investor Relations"
            linkedIn="https://www.linkedin.com/in/kopriwa/"
            avatar={avatar}
          />
        </div>
      </div>
    </div>
  );
}
