import React from 'react';
import ImageSet from './ImageSet';

export default function Profile({
  firstName,
  lastName,
  position,
  linkedIn,
  avatar,
}) {
  return (
    <div className="profile-item">
      <div className="profile-item-inner">
        <div className="avatar-wrapper">
          <ImageSet
            {...avatar}
            className="avatar"
            alt={`${firstName} ${lastName}`}
            title={`${firstName} ${lastName}`}
          />
        </div>
        <div className="identity">
          <h5>
            {`${firstName} ${lastName}`}
          </h5>
          <p className="position text-xxs">{position}</p>
          <a
            className="follow text-xs"
            target="_blank"
            href={linkedIn}
            rel="noreferrer noopener"
          >
            Follow {firstName} on LinkedIn
          </a>
        </div>
      </div>
    </div>
  )
}
