import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import ImageSet from '../elements/ImageSet';
import SectionHeader from './partials/SectionHeader';
import featureSplit1 from './../../assets/images/business/architecture-3093581_1920-xl.jpg';
import featureSplit2 from './../../assets/images/business/architecture-3107302_1920-xl.jpg';
import featureSplit3 from './../../assets/images/business/business-962358_1920-xl.jpg';
import featureSplit1Webp from './../../assets/images/business/architecture-3093581_1920-xl.jpg?format=webp';
import featureSplit2Webp from './../../assets/images/business/architecture-3107302_1920-xl.jpg?format=webp';
import featureSplit3Webp from './../../assets/images/business/business-962358_1920-xl.jpg?format=webp';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'We advise our affiliates',
    paragraph: 'We’re germinating new businesses and expert networking. Your satisfaction and pride is driving everyone forward'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Real time decisions
                </div>
                <h3 className="mt-0 mb-12">
                  Businesses that can compete
                </h3>
                <p className="m-0">
                  We build software that lets organizations integrate their data, their decisions, and their operations into one platform. Our software empowers entire organizations to answer complex questions quickly by bringing the right data to the people who need it.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <ImageSet
                  webp={featureSplit1Webp}
                  alt="Features split 01"
                  {...featureSplit1}
                  sizes="(min-width: 840px) 528px, (min-width: 660px) 436px, calc(95.29vw - 18px)"
                />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Centralized Server-based
                </div>
                <h3 className="mt-0 mb-12">
                  We deliver scalable cloud businesses
                </h3>
                <p className="m-0">
                  Classic infrastructures deployed on commercial or open source DB systems with added intrusion monitoring and security.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <ImageSet
                  webp={featureSplit2Webp}
                  alt="Features split 02"
                  {...featureSplit2}
                  sizes="(min-width: 840px) 528px, (min-width: 660px) 436px, calc(95.29vw - 18px)"
                />
              </div>
            </div>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Time to market
                </div>
                <h3 className="mt-0 mb-12">
                  Technical choices drive investor ROI
                </h3>
                <p className="m-0">
                  Kopaxgroup works around the clock to put latest features in the hands of end users. It eliminates the tradeoff between stability and speed by delivering continuous, automated updates without disrupting operations.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <ImageSet
                  webp={featureSplit3Webp}
                  alt="Features split 03"
                  {...featureSplit3}
                  sizes="(min-width: 840px) 528px, (min-width: 660px) 436px, calc(95.29vw - 18px)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
