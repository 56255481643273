import React from 'react';
import Experts from '../../components/sections/experts/Experts';

export default function ExpertsView({
  data,
  ...rest
}) {
  return (
    <Experts data={data} />
  );
}
