import React from 'react';

export default function ContactPartner({
  company
}) {
  return (
    <div className="contact-partner">
      <div className="left half">
        <h1>Contact</h1>
      </div>
      <div className="right half">
        <div className="hq">
          <h6>Headquarters</h6>
          <address className="text-xxs grey-text">
            <div>
              {company.legalName}
            </div>
            <div>
              {company.streetAddressLine1}
            </div>
            <div>
              {company.streetAddressLine2}
            </div>
            <div>
              {company.country}
            </div>
          </address>
        </div>
        <div className="general-inquiries">
          <h6>General Inquiries</h6>
          <a href="mailto:info@kopaxgroup.com" className="text-xxs grey-text">
            info@kopaxgroup.com
          </a>
        </div>
        <div className="general-inquiries">
          <h6>Investment Proposals</h6>
          <a href="mailto:vc@kopaxgroup.com" className="text-xxs grey-text">
            vc@kopaxgroup.com
          </a>
        </div>
      </div>
    </div>
  )
}
