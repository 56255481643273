import React from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import SchoolIcon from '@material-ui/icons/School';
import BusinessIcon from '@material-ui/icons/Business';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import TouchAppIcon from '@material-ui/icons/TouchApp';


const eventList = [
  {
    title: 'Kopax Company launch',
    subtitle: 'Hong Kong, Hong Kong',
    description: 'Creation of Kopaxgroup with the help of Mr Janet as early investor.',
    date: 'June 2015',
    icon: BusinessIcon,
  },
  {
    title: 'Yeutech Company launch',
    subtitle: 'Ho-Chi-Minh City, Vietnam',
    description: 'Creation of Yeutech, our R&D partner in SEA.',
    date: 'November 2017',
    icon: SchoolIcon,
  },
  {
    title: 'Proof of concepts',
    subtitle: 'Achieved multiple R&D POCs',
    description: 'In house: PaaS (Platform as a service), DevOps automation tools, automated code quality control, web components factory accelerating company incubation.',
    date: 'April 2018',
    icon: TouchAppIcon,
  },
  {
    title: 'Kopaxgroup Back-End services',
    subtitle: 'Tailored integrated SaaS Back-End',
    description: 'Build to improve and transform our cloud strategy including internal optimization of our business processes.',
    date: 'December 2018',
    icon: CloudDoneIcon,
  },
  {
    title: 'Proof of concepts',
    subtitle: 'Achieved multiple R&D POCs',
    description: 'In house: SaaS CMS, SaaS CRM, Security SSO, Messaging micro services, Android and iOS capability with automation',
    date: 'January 2019',
    icon: TouchAppIcon,
  },
  {
    title: 'First B2C customer',
    subtitle: 'Paris, France',
    description: 'Specification, designed and in house online developed trading platform.',
    date: 'December 2020',
    icon: BusinessCenterIcon,
  },
  {
    title: 'Our priorities',
    subtitle: 'Internet business expansion',
    description: [
      'Grow our associates expert network',
      'Propulse investors-unicorns bets',
      'Sustain-long term profitable JV\'s',
    ],
    date: '2021 - present',
    icon: AccountTreeIcon,
  },
  {
    title: 'Business strategy',
    subtitle: 'We advises our partners',
    description: [
      'Business digitization advisory',
      'Investors technology sourcing assistance',
      'Cloud solutions and operations sourcing management'
    ],
    date: '2021 - present',
    icon: AccountBalanceIcon
  }
].map((event) => ({
  iconStyle: {
    background: 'rgb(0, 130, 122)',
    color: '#fff'
  },
  contentStyle: {
    background: 'rgb(0, 130, 122)',
    color: '#fff',
  },
  ...event,
})).reverse();

export default function TimeLine(props) {
  return (
    <div className="has-bg-color invert-color text-xxs timeline">
      <h3>Building a world that works better</h3>
      <h6>Our Time Line</h6>
      <VerticalTimeline>
        {eventList.map(({
          title,
          subtitle,
          description,
          date,
          icon: Icon,
          iconStyle,
          contentStyle,
        }, i) => (
          <VerticalTimelineElement
            key={description + title}
            className="vertical-timeline-element--work"
            contentStyle={contentStyle}
            contentArrowStyle={i === 0 ? { borderRight: '7px solid  rgb(33, 150, 243)' } : {}}
            date={date}
            iconStyle={iconStyle}
            icon={<Icon />}
          >
            <h3 className="vertical-timeline-element-title">{title}</h3>
            <h4 className="vertical-timeline-element-subtitle">{subtitle}</h4>
            {typeof description === 'string' ? (
              <p>
                {description}
              </p>
            ) : (
              <ul>
                {description.map((item) => (
                  <li key={item}>
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </div>
  );
}
