import React, { useCallback, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppContext } from '@yeutech/app-context';
import lookup from 'country-code-lookup';
import {
  Link,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import doc from '../../../assets/images/files/doc-sm.png';
import pdf from '../../../assets/images/files/pdf-sm.png';
import { validateEmail } from '../../../utils/string';
import Input from '../../form/Input';

function ThumbComponent({ files, setFiles }) {
  return files.length > 0 && (
    <div className="pt-16">
      {files.map((file, i) => (
        <div key={file.name}>
          <div className="text-xxs file-item">
            <img
              src={file.type.includes('pdf') ? pdf : doc}
              alt={file.name}
              title={file.name}
              width="56"
            />
            <p>
              {file.name}
            </p>
            <button
              type="button"
              onClick={() => {
                setFiles(files.filter((f, j) => j !== i));
                document.querySelector('input[type="file"][name="resume"]').value = '';
              }}
            >
              <CancelIcon
                fontSize="small"
              />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default function ApplyPositionForm({
  name = 'apply-position-form',
  company,
  job,
}) {
  const { locale } = useContext(AppContext);
  const [pristine, setPristine] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(undefined);
  const [validation, setValidation] = useState({});
  const [resume, setResume] = useState(undefined);
  const location = useLocation();
  const { path, params } = useRouteMatch();
  const { position } = useParams();
  const countryCodeIso2 = useMemo(() => {
    const arr = job.location.split(',');
    return lookup.byCountry(arr[arr.length - 1].trim())?.iso2 || lookup.byCountry(company.country)?.iso2 || 'FR';
  }, [job, company]);


  const handleValidate = useCallback((e) => {
    setPristine(false);
    let newValidation = {};
    const {
      firstName,
      lastName,
      email,
      city,
      message,
      telephone,
    } = e.currentTarget;

    const values = {
      firstName: firstName.value.trim(),
      lastName: lastName.value.trim(),
      email: email.value.trim(),
      city: city.value.trim(),
      message: message.value.trim(),
      telephone: telephone.value.trim().replace(/ /g, ''),
    };
    let shouldValidateAll = e.target.name === name;
    if (!values.firstName.length) {
      newValidation.firstName = 'First name is missing';
    } else if (values.firstName.length < 2) {
      newValidation.firstName = 'First name should be at least 2 characters';
    }
    if (!values.lastName.length) {
      newValidation.lastName = 'Last name is missing';
    } else if (values.lastName.length < 2) {
      newValidation.lastName = 'Last name should be at least 2 characters';
    }
    if (!values.email.length) {
      newValidation.email = 'Email is missing';
    } else if (!validateEmail(values.email)) {
      newValidation.email = 'Email is invalid';
    }
    if (!values.telephone.length) {
      newValidation.telephone = 'Telephone is missing';
    } else if (values.telephone.length > 4 && values.telephone.length < 9) {
      newValidation.telephone = 'Telephone is invalid';
    }
    if (!values.telephone.length) {
      newValidation.telephone = 'Telephone is missing';
    }
    if (!values.city.length) {
      newValidation.city = 'City is missing';
    } else if (values.city.length < 3) {
      newValidation.city = 'Place of residence should be at least 3 characters';
    }
    if (!values.message.length) {
      newValidation.message = 'Message cannot be empty';
    } else if (values.message.length < 100) {
      newValidation.message = 'Message should be at least 100 characters';
    } else if (values.message.length > 2000) {
      newValidation.message = 'Message cannot contain more than 2000 characters';
    }
    if (!resume || resume.length === 0) {
      newValidation.resume = 'You must add your resume';
    }
    if (shouldValidateAll) {
      setValidation(newValidation);
    } else {
      const clearedValidation = {};
      Object.keys(validation).forEach((v) => {
        if (newValidation[v] && validation[v]) {
          clearedValidation[v] = newValidation[v];
        }
      });
      setValidation({
        ...clearedValidation,
        ...(newValidation[e.target.name] ? { [e.target.name]: newValidation[e.target.name] } : {}),
      });
      return validation;
    }

    return newValidation;
  }, [setPristine, validation, name, resume]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setError(undefined);
    (async () => {
      try {
        const {
          firstName,
          lastName,
          email,
          city,
          message,
          telephone,
        } = e.currentTarget;
        const values = {
          firstName: firstName.value.trim(),
          lastName: lastName.value.trim(),
          email: email.value.trim(),
          city: city.value.trim(),
          message: message.value.trim(),
          telephone: telephone.value.trim().replace(/ /g, ''),
          job,
        };
        const v = handleValidate(e);
        if (Object.keys(v).length === 0) {
          const formData = new FormData();
          Object.keys(values).forEach((key) => formData.append(key, typeof values[key] === 'object' ? JSON.stringify(values[key]) : values[key]));
          if (!resume[0]) {
            setError('We failed to read your resume, try again using a valid .pdf file.');
            return;
          }
          formData.append('resume', resume[0], resume[0].name);
          setSubmitting(true);
          const response = await fetch('/jobs/apply', {
            method: 'POST',
            body: formData,
          });
          if (response.status === 200) {
            setSubmitted(true);
            window.scrollTo(0, 0);
          } else if (response.status === 412) {
            setError(`You have already applied for this position.`);
          } else {
            setError(`We couldn't submit your application, please try again later.`);
          }
        }
      } catch (err) {
        setError(process.env.NODE_ENV === 'production' ? `We couldn't submit your application, please try again later.` : err.message);
        process.env.NODE_ENV !== 'production' && console.error(err);
      }
      setSubmitting(false);
    })();
  }, [job, handleValidate, resume]);

  return (
    <form
      name={name}
      action={location.pathname}
      onSubmit={handleSubmit}
      method="post"
      encType="multipart/form-data"
      onChange={handleValidate}
    >
      <fieldset>
        {submitted ? (
          <>
            <h5>Application received</h5>
            <p className="text-xxs description">
              It's great, we now of your resume, thanks for taking up the time to fulfill your application.
              We try to answer to all applications in the limit of our capacities and priorities.
              If you do not received an answer after 3 months, you can consider your application to be refused.
              Kopaxgroup will keep your information for at least 2 years, after that time, you are welcome to submit a new application.
            </p>
            <div className="apply-wrapper mt-16">
              <Link
                to={path.split('/:location')[0].replace(':position', params.position)}
                className="button button-transparent mt-16"
              >
                View other {position} positions
              </Link>
            </div>
          </>
        ) : (
          <>
            <legend>Personal information</legend>
            <Input
              name="firstName"
              label="First name"
              error={validation.firstName}
              required
              readOnly={submitting}
            />
            <Input
              name="lastName"
              label="Last name"
              error={validation.lastName}
              required
              readOnly={submitting}
            />
            <Input
              name="email"
              label="Email"
              type="email"
              error={validation.email}
              required
              readOnly={submitting}
            />
            <Input
              name="city"
              label="Place of residence (City)"
              error={validation.city}
              required
              readOnly={submitting}
            />
            <Input
              name="telephone"
              label="Phone number"
              type="tel"
              error={validation.telephone}
              country={countryCodeIso2.toLowerCase()}
              locale={locale}
              required
              readOnly={submitting}
            />
            <Input
              type="dropzone"
              name="resume"
              label="Add your resume"
              required
              error={validation.resume}
              maxSize={20971520} // 20MB
              multiple={false}
              maxFiles={1}
              dragAccept="Drop your resume"
              dragReject="Resume wrong format or bigger than {maxSize}"
              dragActive="Drag 'n' drop your CV here (.pdf, .docx. .doc), or click to select from files"
              accept={[
                'application/pdf',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              ]}
              onDrop={setResume}
              thumbReplace
              thumbComponent={ThumbComponent}
            />
            <h5>Message to the hiring manager</h5>
            <Input
              label="Let the company know that you are interested in working there"
              name="message"
              type="textarea"
              maxLength="2000"
              minLength="100"
              required
              error={validation.message}
            />
            <div
              className={classNames('form-error', 'text-color-error', {
                visible: error,
              })}
            >
              {error}
            </div>
            <button
              name="submit"
              className="button mt-8"
              type="submit"
              disabled={pristine || submitting}
            >
              Submit
              {submitting && (<>{'\xa0'}<CircularProgress size={19} /></>)}
            </button>
          </>
        )}
      </fieldset>
    </form>
  );
}
