import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '@kopaxgroup/image-common/svg/optimized/logo.svg';

export default function PositionSheet({
  data: { company, careers },
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) {
  const { pathname } = useLocation();
  const { position, location, type, title } = useParams();
  const positionCategory = useMemo(() => careers.find((c) => c.id === position), [careers, position]);
  const job = useMemo(() => positionCategory.positionList.find((p) => p.location.toLowerCase() === location.toLowerCase() && p.type.toLowerCase() === type.toLowerCase() && p.title.toLowerCase() === title.toLowerCase()), [positionCategory, location, type, title]);

  const outerClasses = classNames(
    'PositionSheet section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'PositionSheet-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className={innerClasses}>
        <div className="Position-content reveal-from-bottom container" data-reveal-delay="200">
          <header>
            <div className="title-wrapper">
              <h1 className="text-color-low">{job.title}</h1>
              <h6>{job.company || company.name}</h6>
              <div className="location">{positionCategory.title} | {job.location}</div>
            </div>
            <div className="logo-wrapper">
              <Logo />
            </div>
          </header>
          <div className="text-xs">
            <p className="position-company-description">
              {company.description}
            </p>
            <p className="position-job-description">
              {job.description}
            </p>
            Your tasks:
            <ul>
              {job.tasks.map((task) => (
                <li key={task}>
                  {task}
                </li>
              ))}
            </ul>
            Your qualifications:
            <ul>
              {job.qualifications.map((qualification) => (
                <li key={qualification}>
                  {qualification}
                </li>
              ))}
            </ul>
            <h4>What we offer</h4>
            {typeof job.additionalInformation === 'string' ? (
              <p>
                {job.additionalInformation}
              </p>
            ) : (
              <ul>
                {job.additionalInformation.map((item) => (
                  <li key={item}>
                    {item}
                  </li>
                ))}
              </ul>
            )}
            <h4>Interested ?</h4>
            <p>
              {job.apply}
            </p>
          </div>
          <div className="apply-wrapper">
            <Link
              to={`${pathname}/apply`}
              className="button button-transparent"
            >
              Apply
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
