import React from 'react';
import Investors from '../../components/sections/investors/Investors';

export default function InvestorsView({
  data,
  ...rest
}) {
  return (
    <Investors data={data} />
  );
}
