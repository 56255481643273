import React, { useState } from 'react';
import classNames from 'classnames';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function FaqItem({
  question,
  answer
}) {
  const [hidden, setIsHidden] = useState(true);
  return (
    <div className="faq-item collapsible mt-4">
      <div
        className="collapsible-head"
        onClick={() => setIsHidden(!hidden)}
      >
        <div
          className="collapsible-title text-xs"
        >
          <div>{question}</div>
        </div>
        {hidden ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </div>
      <div
        className={classNames('collapsible-body mt-4 text-xxs', {
          visible: !hidden,
        })}
      >
        {answer}
      </div>
      <div className="hr" />
    </div>
  );
}
