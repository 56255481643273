import React from 'react';
import { Link } from 'react-router-dom';

export default function JobItem({
  job,
  indice = 0,
}) {
  return (
    <li
      key={job.id}
      className="mt-0 mb-16 reveal-from-bottom"
      data-reveal-delay={(indice + 1) * 200}
    >
      <h3>{job.title}</h3>
      <p className="text-xs text-color-low">
        {job.excerpt}
      </p>
      <Link
        className="button button-primary"
        to={`/careers/jobs/${job.id}`}
      >
        View {job.positionList.length === 1 ? `position` : `${job.positionList.length} positions`}
      </Link>
    </li>
  );
}
