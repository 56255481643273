import React, { useRef } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../../utils/SectionProps';
import InvestorsImage from '../../../assets/images/business/architecture-3107302_1920-xl.jpg';
import InvestorsImageWebp from '../../../assets/images/business/architecture-3107302_1920-xl.jpg?format=webp';

import ImageSet from '../../elements/ImageSet';
import AboutKopax from './AboutKopax';
import ContactPartner from './ContactPartner';
import InvestorIntroduction from './InvestorIntroduction';
import InvestorFooter from './InvestorFooter';
import InvestorNews from './InvestorNews';
import TimeLine from './TimeLine';
const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Investors = ({
  data: { company },
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'Investors section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'Investors-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );
  const scrollRef = useRef(null)

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="">
        <div className="banner">
          <h2 className="Investors-title title mt-0 mb-0">
            Investor Relations
          </h2>
          <div className="darker-bg" />
          <ImageSet
            webp={InvestorsImageWebp}
            alt="Investors"
            lazy={false}
            {...InvestorsImage}
          />
        </div>
        <div className={innerClasses}>
          <div className="Investors-content">
            <div className="container-sm mb-32">
              <h3 className="mt-0 reveal-from-bottom" data-reveal-delay="200">
                We are looking to <span className="text-color-primary">boost</span> innovative thinkers with <span className="text-color-primary">disruptive ideas</span>
              </h3>
              <blockquote className="reveal-from-bottom" data-reveal-delay="300">
                Focusing on early-stage funding and development, our idea was born from the desire
                of helping founders &amp; their investors to launch and scale startups retaining custom Cloud
                based SaaS technologies.
              </blockquote>
            </div>
            <div className="pt-32 reveal-from-bottom" data-reveal-delay="400">
              <InvestorIntroduction scrollRef={scrollRef} />
            </div>
            <div className="container-md">
              <AboutKopax />
            </div>
            <div className="container-md" ref={scrollRef}>
              <TimeLine />
            </div>
            <div className="container-sm">
              <ContactPartner
                company={company}
              />
            </div>
          </div>
        </div>
        <InvestorNews />
        <InvestorFooter />
      </div>
    </section>
  );
}

Investors.propTypes = propTypes;
Investors.defaultProps = defaultProps;

export default Investors;
