import React from 'react';
import Jobs from '../../components/sections/jobs/Jobs';

export default function JobsView({
  data,
  ...rest
}) {
  return (
    <Jobs data={data} />
  );
}
