import React, { useCallback } from 'react';
import bg from '../../../assets/images/investors/dc_operator.jpg';
import ImageSet from '../../elements/ImageSet';

export default function InvestorIntroduction({
  scrollRef
}) {
  const executeScroll = useCallback(() => scrollRef.current.scrollIntoView({ behavior: 'smooth' }), [scrollRef]);
  return (
    <div className="investor-introduction">
      <div
        className="container text-content"
      >
        <h3>Our Purpose</h3>
        <p>
          Building a world that works better.
        </p>
        <button
          type="button"
          className="button button-transparent"
          onClick={executeScroll}
        >
          View the timeline
        </button>
      </div>
      <div
        className="introduction-bg"
      />
      <ImageSet
        {...bg}
        className="image-bg"
        alt="DevOps operator"
        title="DevOps operator"
      />
    </div>
  );
}
