import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';
import LogoSvg from '@kopaxgroup/image-common/svg/optimized/logo.svg';
import BrandSvg from '@kopaxgroup/image-common/svg/optimized/brand.svg';

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="m-0">
        <Link to="/">
          <Image
            src={LogoSvg}
            alt="Logo Kopax"
            className="logo"
            // width={36}
            // height={36}
          />
          <Image
            src={BrandSvg}
            alt="Brand Kopax"
            className="brand"
            // width={118}
          />
        </Link>
      </h1>
    </div>
  );
}

export default Logo;
