import React from 'react';
import PositionSheet from '../../components/sections/position/PositionSheet';

export default function PositionSheetView({
  data,
  ...rest
}) {
  return (
    <PositionSheet data={data} />
  );
}
