export const env = require(`./env/${process.env.REACT_APP_ENV}`);
if (!['prod', 'test'].includes(process.env.REACT_APP_ENV)) {
  // eslint-disable-next-line no-console
  console.log('Loading', process.env.REACT_APP_ENV, 'config');
}

export const SPLIT_CHARS = {
  INVISIBLE: ' ‎',
  ACCOMPLISHEMENTS: {
    en: 'Accomplishments:\n\n',
    fr: 'Réalisations :\n\n',
  },
};
