import React, { useMemo } from 'react';
import Image from './Image';

export default function ImageSet({
  webp,
  src,
  srcSet,
  width,
  height,
  lazy = true,
  images,
  toString,
  sizes: userSizes,
  type: userType,
  placeholder,
  ...rest
}) {
  const sizes = useMemo(() => {
    return '100vw';
    // return '(min-width: 1000px) 896px, 92.35vw';
    // return images ?
    //   images.map((image, i) => i + 1 !== images.length ? `(max-width: ${image.width}px) ${image.width}px` : '100vw')
    //     .join(', ') :
    //   undefined;
  }, []);
  return (
    <picture>
      {webp && (
        <source
          type="image/webp"
          srcSet={webp.srcSet}
          sizes={userSizes || sizes}
        />
      )}
      <Image
        src={src}
        sizes={userSizes || sizes}
        loading={lazy ? 'lazy' : undefined}
        width={width}
        height={height}
        srcSet={srcSet}
        placeholder={placeholder}
        {...rest}
      />
    </picture>
  );
}
