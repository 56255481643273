import React from 'react';
import Media from '../../components/sections/media/Media';

export default function MediaView({
  ...rest
}) {
  return (
    <Media />
  );
}
