import cn from 'classnames';
import React, { useEffect } from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import Partner from './Partner';

export default function PartnerList({
  partners,
}) {
  const { path, url, isExact } = useRouteMatch();
  useEffect(() => {
    if (!isExact) {
      document.body.classList.add('no-overflow');
    } else {
      document.body.classList.remove('no-overflow');
    }
  }, [isExact])
  return (
    <>
      <ul className="partner-list">
        {partners.map((partner, i) => (
          <li
            style={partner.listStyle}
            key={partner.name}
            title={partner.name}
            className="reveal-from-bottom" data-reveal-delay={(600 + i * 100).toString()}
          >
            <Link to={`${url}/${partner.slug}`}>
              <img
                src={partner.logo}
                title={partner.name}
                alt={partner.name}
              />
            </Link>
          </li>
        ))}
      </ul>
      <div
        className={cn(
          'partner-item-wrapper',
          {
            visible: !isExact,
          },
        )}
      >
        <Switch>
          {partners.map((partner) => (
            <Route
              key={partner.name}
              path={`${path}/:partnerSlug`}
            >
              <Partner
                partners={partners}
              />
            </Route>
          ))}
        </Switch>
      </div>
    </>
  );
}
