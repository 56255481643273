import React from 'react';

export default function AboutKopax(props) {
  return (
    <div className="about-kopax">
      <div className="container-sm investors-list-wrapper list-wrapper">
        <div className="jobs-list-left list-left reveal-from-bottom" data-reveal-delay="600">
          <blockquote>
            We help companies mapping and implementing business processes, allowing them to scale, and advising how founders and investors must best manage their operations, making themselves ‘fit for market’.
          </blockquote>
        </div>
        <div className="jobs-list-right list-right text-sm">
          <ul>
            <li className="reveal-from-bottom" data-reveal-delay="800">
              <h6 className="text-xs">How we do it?</h6>
              <p className="text-xxs">
                We believe in a straightforward collaborative approach and assist in everything from acquisitions to team building.
                We're pooling both resources and brain-power to assist early-stage entrepreneurs with their investors for building B2B2C/enterprise startups.
              </p>
            </li>
            <li className="reveal-from-bottom" data-reveal-delay="1000">
              <h6 className="text-xs">What we do?</h6>
              <p className="text-xxs">
                We validate technology choices.
                Checking that technologies chosen for support are meaningful, accessible, and educative.
                We help to design business mapping and business process logic and we coordinate all digital touch points.
              </p>
            </li>
            <li className="reveal-from-bottom" data-reveal-delay="1200">
              <h6 className="text-xs">When we do it?</h6>
              <p className="text-xxs">
                Ideally after having secured venture financing from EUR $1 to $6 million in their various stages for disruptive to-be Unicorns.
              </p>
            </li>
            <li className="reveal-from-bottom" data-reveal-delay="1400">
              <h6 className="text-xs">Where we do it?</h6>
              <p className="text-xxs">
                While our team is small, we work across Europe and SE Asia via digital channels. When needed, we offer early-stage strategic mentorship in mission critical domains.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
